import React from 'react';
import {getCourseAPI} from "../views/content/SkillsCoursesViewContent/utils";
import {
    getAllCredentials,
    saveBulkSuggestionsAssignments, saveGroupsSkillsAssignmentAPI, saveLearnerCredentialsAssignmentAPI,
    saveLearnerSkillsAssignmentAPI,
    saveSuggestionAssignmentAPI
} from "./utils/teamsAssignments";
import {getUserName} from "@iblai/ibl-web-react-common";

const useTeamsAssignments = () => {

    const getCoursesForSelectDropdown = async (input="", callback) => {
        callback(await getCourseAPI(12, "", input, false))
    }

    const getProgramsForSelectDropdown = async (input="", callback) => {
        callback(await getCourseAPI(12, "", input, true))
    }

    const getPathwaysForSelectDropdown = async (input="", callback, isProgram=true) => {
        callback(await getCourseAPI(12, "", input, false, true, true))
    }
    const getSkillsForSelectDropdown = async (input="", callback) => {
        callback(await getCourseAPI(12, "", input, false, true, false, true))
    }

    const getCredentialsForSelectDropdown = async (input="", callback) => {
        await getAllCredentials(input, callback)
    }



    const handleSaveLearnersAssignmentSuggestions = ({contentType='course', assignmentData, contentKeyID="course_id"}) => {
        //TODO saving must be in bulk and not individual looping
        //console.log({assignmentData})
        const contents = assignmentData?.content ?? []
        const learners = assignmentData?.learners ?? []
        let allPromises = []
        contents.forEach((_content)=>{
            learners.forEach((_learner)=>{
                allPromises.push(saveSuggestionAssignmentAPI({contentType, data:{
                    user_id:_learner?.user_id,
                        [contentKeyID]:_content?.[contentKeyID]
                    }}))
            })
        })
       return Promise.all(allPromises)
    }

    const handleSaveTeamsAssignmentSuggestions = ({contentType='course', assignmentData, contentKeyID="course_id"}) => {
        //TODO saving must be in bulk and not individual looping
        //console.log({assignmentData})
        const contents = assignmentData?.content ?? []
        const teams = assignmentData?.teams ?? []
        let allPromises = []
        contents.forEach((_content)=>{
            teams.forEach((_team)=>{
                allPromises.push(saveSuggestionAssignmentAPI({contentType, data:{
                        group_id:_team?.id,
                        [contentKeyID]:_content?.[contentKeyID],
                    }, isGroup:true}))
            })
        })
        return Promise.all(allPromises)
            .then((resp)=>resp.filter((pre)=>pre).length === allPromises.length)
            .catch(()=>false)
    }

    const handleSaveBulkLearnersAssignmentSuggestions =  ({contentType='course', assignmentData, contentKeyID="course_id"}) => {
        const contents = assignmentData?.content ?? []
        const learners = assignmentData?.learners ?? []
        const suggestionData = []
        contents.forEach((_content)=>{
            learners.forEach((_learner)=>{
                suggestionData.push({
                    user_id:_learner?.user_id,
                    [contentKeyID]:_content?.[contentKeyID]
                })
            })
        })
        return saveBulkSuggestionsAssignments({contentType, data:suggestionData})
    }

    const handleSaveSkillsLearnersAssignment = (assignmentData) =>{
        const learners = assignmentData?.learners ?? []
        const skills = assignmentData?.skills
        let allPromises = []
        learners.forEach((_learner)=>{
            allPromises.push(saveLearnerSkillsAssignmentAPI({
                username:_learner?.username,
                point_data:skills,
                overwrite:false
            }))
        })
        return Promise.all(allPromises)
            .then((resp)=>resp.filter((pre)=>pre).length === allPromises.length)
            .catch(()=>false)
    }
    const handleSaveCredentialsLearnersAssignment = (assignmentData) =>{
        const learners = assignmentData?.learners ?? []
        const credentials = assignmentData?.credentials
        let allPromises = []
        credentials.forEach((_cred)=>{
            allPromises.push(saveLearnerCredentialsAssignmentAPI({
                user_ids:learners.map((_learner)=>(_learner?.user_id)),
                credential_id:_cred
            }, "users"))
        })
        return Promise.all(allPromises)
            .then((resp)=>resp.filter((pre)=>pre).length === allPromises.length)
            .catch(()=>false)
    }

    const handleSaveCredentialsGroupsAssignment = (assignmentData) =>{
        const teams = assignmentData?.teams ?? []
        const credentials = assignmentData?.credentials
        let allPromises = []
        credentials.forEach((_cred)=>{
            allPromises.push(saveLearnerCredentialsAssignmentAPI({
                group_ids:teams.map((_team)=>(_team.id)), //learners.map((_learner)=>(_learner?.id)),
                credential_id:_cred
            }, "groups"))
        })
        return Promise.all(allPromises)
            .then((resp)=>resp.filter((pre)=>pre).length === allPromises.length)
            .catch(()=>false)
    }

    const handleSaveSkillsGroupsAssignment = (assignmentData) =>{
        const teams = assignmentData?.teams ?? []
        const skills = assignmentData?.skills
        let allPromises = []
        teams.forEach((_team)=>{
            allPromises.push(saveGroupsSkillsAssignmentAPI({
                group_id:_team?.id,
                point_data:skills,
                overwrite:false
            }))
        })
        return Promise.all(allPromises)
            .then((resp)=>resp.filter((pre)=>pre).length === allPromises.length)
            .catch(()=>false)
    }

    return {getCoursesForSelectDropdown,
        handleSaveLearnersAssignmentSuggestions,
        getProgramsForSelectDropdown, getPathwaysForSelectDropdown,
        handleSaveBulkLearnersAssignmentSuggestions,
        handleSaveTeamsAssignmentSuggestions,
        getSkillsForSelectDropdown,
        handleSaveSkillsLearnersAssignment,
        handleSaveSkillsGroupsAssignment,
        handleSaveCredentialsLearnersAssignment,
        handleSaveCredentialsGroupsAssignment,
        getCredentialsForSelectDropdown
    }
};

export default useTeamsAssignments;