import React, { useContext, useEffect, useRef, useState } from 'react';

import './StudioAuthoringSettingsViewContent.css';
import { getTenant } from '../../../hooks/helpers';
import { setStudioLogo, setStudioDarkLogo } from '../../../hooks/utils/studio';
import { AppDataContext, IBLSpinLoader } from '@iblai/ibl-web-react-common';
import useTenant from '../../../hooks/useTenant';
import {
  getPlatformDomainStatus,
  updateTenantMetaData,
} from '../../../hooks/utils/tenant';
import { handleCopy } from './copyUtils';

const StudioAuthoringSettingsViewContent = () => {
  const { setTenantMetaData } = useContext(AppDataContext);
  const {
    handleUpdateTenantMetaData,
    tenantMetaData,
    tenantMetaDataLoading,
    METADATAS,
  } = useTenant();
  const { setNotification } = useContext(AppDataContext);
  const inputLightImgUploadRef = useRef(null);
  const inputDarkImgUploadRef = useRef(null);
  const [isUpdatingTenantDomain, setIsUpdatingTenantDomain] = useState(false);
  const [platformDomains, setPlatformDomains] = useState({});
  const [isEditSkills, setIsEditSkills] = useState(false);
  const [isEditAnalytics, setIsEditAnalytics] = useState(false);
  const [isEditMentor, setIsEditMentor] = useState(false);
  const handleLightUploaderTrigger = () => {
    inputLightImgUploadRef.current.click();
  };

  const handleDarkUploaderTrigger = () => {
    inputDarkImgUploadRef.current.click();
  };

  const [lightLogoURL, setLightLogoURL] = useState('');
  const [darkLogoURL, setDarkLogoURL] = useState('');
  const [lightLogoURLUpdating, setLightLogoURLUpdating] = useState(false);
  const [darkLogoURLUpdating, setDarkLogoURLUpdating] = useState(false);

  const [domainStatus, setDomainStatus] = useState({});

  useEffect(() => {
    if (tenantMetaData) {
      setPlatformDomains({
        spa_domains: {
          skills: tenantMetaData?.spa_domains?.skills
            ? { ...tenantMetaData?.spa_domains?.skills }
            : {
                active: false,
                domain: '',
              },
          mentor: tenantMetaData?.spa_domains?.mentor
            ? { ...tenantMetaData?.spa_domains?.mentor }
            : {
                active: false,
                domain: '',
              },
          analytics: tenantMetaData?.spa_domains?.analytics
            ? { ...tenantMetaData?.spa_domains?.analytics }
            : {
                active: false,
                domain: '',
              },
        },
      });
    }
  }, [tenantMetaData]);

  useEffect(() => {
    getPlatformDomainStatus(localStorage.getItem('tenant'), (data) => {
      setDomainStatus(data);
    });
  }, []);

  const handleLightImgUploadChange = async (e) => {
    if (!e) {
      return;
    }
    setLightLogoURLUpdating(true);
    const file = e?.target?.files[0];
    inputLightImgUploadRef.current.value = '';
    const formData = new FormData();
    formData.append('file', file, file.name);
    setStudioLogo(formData, (response) => {
      setLightLogoURL(response.url);
      setLightLogoURLUpdating(false);
    });
  };

  const handleDarkImgUploadChange = async (e) => {
    if (!e) {
      return;
    }
    setDarkLogoURLUpdating(true);
    const file = e?.target?.files[0];
    inputDarkImgUploadRef.current.value = '';
    const formData = new FormData();
    formData.append('file', file, file.name);
    setStudioDarkLogo(formData, (response) => {
      setDarkLogoURL(response.url);
      setDarkLogoURLUpdating(false);
    });
  };

  const handleLoadSettingsData = () => {
    setLightLogoURL(
      `${process.env.REACT_APP_IBL_AXD_URL}/api/core/orgs/${getTenant()}/logo/`
    );
    setDarkLogoURL(
      `${process.env.REACT_APP_IBL_AXD_URL}/api/core/orgs/${getTenant()}/dark-mode-logo/`
    );
  };

  useEffect(() => {
    handleLoadSettingsData();
  }, []);

  const [focusedMetaData, setFocusedMetaData] = useState('');

  const handleMetaDataCheckBoxChange = (metaData) => {
    setFocusedMetaData(metaData.slug);
    const updatedValue = !(
      tenantMetaData?.[metaData.slug] ?? metaData.defaultValue
    );
    handleUpdateTenantMetaData(
      { [metaData.slug]: updatedValue },
      (successfullyUpdate) => {
        setFocusedMetaData('');
        if (successfullyUpdate === true) {
          setTenantMetaData({
            ...tenantMetaData,
            [metaData.slug]: updatedValue,
          });
        } else {
          setNotification({
            msg: 'An error occurred while updating tenant settings',
            success: false,
          });
        }
      }
    );
  };

  const handleDomainChangeSave = (callback = null) => {
    setIsUpdatingTenantDomain(true);
    const newMetadata = {
      ...tenantMetaData,
      ...platformDomains,
    };
    updateTenantMetaData(newMetadata, (successfullyUpdate) => {
      setIsUpdatingTenantDomain(false);
      callback && callback();
    });
  };

  const handleDomainChange = (event) => {
    const { name, value } = event.target;
    setPlatformDomains({
      platform: getTenant(),
      spa_domains: {
        ...platformDomains['spa_domains'],
        [name]: {
          ...platformDomains['spa_domains'][name],
          domain: value,
          active: false,
        },
      },
    });
  };

  const handleMentorDomainSave = () => {
    if (
      tenantMetaData?.spa_domains?.mentor?.domain !==
      platformDomains?.spa_domains?.mentor.domain
    ) {
      handleDomainChangeSave(() => setIsEditMentor(false));
    }
  };

  const handleSkillsDomainSave = () => {
    if (
      tenantMetaData?.spa_domains?.skills?.domain !==
      platformDomains?.spa_domains?.skills.domain
    ) {
      handleDomainChangeSave(() => setIsEditSkills(false));
    }
  };

  const handleAnalyticsDomainSave = () => {
    if (
      tenantMetaData?.spa_domains?.analytics?.domain !==
      platformDomains?.spa_domains?.analytics.domain
    ) {
      handleDomainChangeSave(() => setIsEditAnalytics(false));
    }
  };

  const handleCopyWrapper = (text) => {
    copyToClipboard(text);
    handleCopy(text, domainStatus);
  };

  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        console.log('Copied to clipboard:', text);
      })
      .catch((err) => {
        console.error('Failed to copy: ', err);
      });
  };

  return (
    <>
      <div className="div-block-183">
        <div className="div-block-205">
          <div className="div-block-206"></div>
          <div className="text-block-113">STUDIO | SETTINGS</div>
        </div>
      </div>
      <div className="div-block-326 l map">
        <div
          react-hook="useStudioSettings"
          react-component="StudioSettingsTable"
          className="table_cont-2"
        >
          <div className="div-block-325 w_bg nbg">
            <div className="text-block-172">Platform Logos</div>
            <div className="table">
              <div className="tr">
                <div className="tc tcell">
                  <div className="m-2">Light Logo</div>
                </div>
                <div className="tc tcell _35">
                  {lightLogoURLUpdating ? (
                    <IBLSpinLoader color={'#FFF'} size={20} />
                  ) : (
                    <div className="div-block-271">
                      <img
                        className="image-94"
                        src={lightLogoURL}
                        alt=""
                        loading="lazy"
                      />
                    </div>
                  )}
                </div>
                <div className="tc tcell _32-5">
                  <div
                    onClick={() => handleLightUploaderTrigger()}
                    className="div-block-327 first active edit edit_logo"
                  >
                    <div className="text-block-173">Edit</div>
                  </div>
                  <div className="html-embed-10 w-embed">
                    <input
                      onChange={handleLightImgUploadChange}
                      ref={inputLightImgUploadRef}
                      type="file"
                      prop-events-names="onChange"
                      prop-events-value-onchange="handleLightLogoOnChange"
                      className="file-upload light-logo"
                      accept=".jpg, .jpeg, .png, .gif, .svg"
                      style={{ display: 'none' }}
                      autoComplete="off"
                    />
                  </div>
                </div>
              </div>
              <div className="tr">
                <div className="tc tcell">
                  <div className="m-2">Dark Logo</div>
                </div>
                <div className="tc tcell _35">
                  {darkLogoURLUpdating ? (
                    <IBLSpinLoader color={'#FFF'} size={20} />
                  ) : (
                    <div className="div-block-271">
                      <img
                        className="image-94"
                        src={darkLogoURL}
                        alt=""
                        loading="lazy"
                      />
                    </div>
                  )}
                </div>
                <div className="tc tcell _32-5">
                  <div
                    onClick={() => handleDarkUploaderTrigger()}
                    className="div-block-327 first active edit edit_logo"
                  >
                    <div className="text-block-173">Edit</div>
                  </div>
                  <div className="html-embed-10 w-embed">
                    <input
                      onChange={handleDarkImgUploadChange}
                      ref={inputDarkImgUploadRef}
                      type="file"
                      prop-events-names="onChange"
                      prop-events-value-onchange="handleDarkLogoOnChange"
                      className="file-upload dark-logo"
                      accept=".jpg, .jpeg, .png, .gif, .svg"
                      style={{ display: 'none' }}
                      autoComplete="off"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="div-block-326 l map tenant-settings-form">
        <div
          react-hook="useStudioSettings"
          react-component="StudioSettingsTable"
          className="table_cont-2"
        >
          <div className="div-block-325 w_bg nbg">
            <div className="text-block-172">Tenant settings</div>
            {tenantMetaDataLoading ? (
              <IBLSpinLoader
                size={30}
                color={'#FFF'}
                containerHeight={'100px'}
              />
            ) : (
              <div className="table">
                {METADATAS.map((_metadata, index) => (
                  <div key={`metadata-setting-${index}`} className="tr">
                    <div className="tc tcell _65">
                      <div className="m-2">{_metadata.label}</div>
                    </div>
                    <div className="tc tcell _20">
                      <div
                        onClick={(e) => {
                          e.preventDefault();
                          handleMetaDataCheckBoxChange(_metadata);
                        }}
                        className="email-switching-block justify-start-flex w-embed"
                      >
                        {focusedMetaData === _metadata.slug ? (
                          <span style={{ marginLeft: '10px' }}>
                            <IBLSpinLoader size={20} color={'#FFF'} />
                          </span>
                        ) : (
                          <label className="user-switcher">
                            <input
                              /*onChange={
                                                      handleAddingUnregisteredUsersCheck
                                                    }*/
                              type="checkbox"
                              checked={
                                tenantMetaData?.[_metadata.slug] ??
                                _metadata.defaultValue
                              }
                            />
                            <span className="user-switcher-slider round"></span>
                          </label>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
      <div
        react-component="table"
        className="table domain-status-settings div-block-325 w_bg nbg"
      >
        <div className="text-block-172">Platform Custom Domains</div>
        <div className="text-block-172 small-text">
          Clicking on a CName/Cloudfront Name/Value will copy it to clipboard.
        </div>
        <div className="th">
          <div className="tc _10">
            <div className="sm">Platform</div>
          </div>
          <div className="tc _10">
            <div className="w_sort">
              <div className="sm">Domain</div>
            </div>
          </div>
          <div className="tc _10">
            <div className="w_sort">
              <div className="sm">Status</div>
            </div>
          </div>
          <div className="tc _10">
            <div className="w_sort">
              <div className="sm">CName Cert Name</div>
            </div>
          </div>
          <div className="tc _10">
            <div className="w_sort">
              <div className="sm">CName Cert Value</div>
            </div>
          </div>
          <div className="tc _10">
            <div className="w_sort">
              <div className="sm">Cloudfront Name</div>
            </div>
          </div>
          <div className="tc _10">
            <div className="w_sort">
              <div className="sm">Cloudfront Value</div>
            </div>
          </div>
          <div className="tc _10">
            <div className="w_sort">
              <div className="sm"></div>
            </div>
          </div>
        </div>
        {process.env.REACT_APP_IBL_MULTITENANCY_ENABLED === 'true' &&
        tenantMetaDataLoading ? (
          <IBLSpinLoader containerHeight={'200px'} size={20} color={'white'} />
        ) : (
          <>
            <div className="tr">
              <div className="tc tcell _10">
                <div className="m">Mentor</div>
              </div>
              <div className="tc tcell _10">
                <div className="m">
                  {isEditMentor ? (
                    <div
                      className="w-layout-hflex flex-block-14"
                      style={{ border: '1px solid' }}
                    >
                      <input
                        name="mentor"
                        value={platformDomains?.spa_domains?.mentor?.domain}
                        className="text-field-10 w-input"
                        maxLength={100}
                        data-name="Field 6"
                        type="text"
                        id="field-6"
                        onChange={handleDomainChange}
                        placeholder="e.g. analytics.iblai.app"
                      />
                    </div>
                  ) : (
                    platformDomains?.spa_domains?.mentor?.domain || ''
                  )}
                </div>
              </div>
              <div className="tc _10">
                <div className="m small">
                  {platformDomains?.spa_domains?.mentor?.active
                    ? 'Active'
                    : 'Not Active'}
                </div>
              </div>
              <div
                className="tc _10 ellipsis clickable"
                onClick={() =>
                  handleCopyWrapper(
                    domainStatus?.mentor?.cname_records?.certificate_validation
                      ?.name
                  )
                }
              >
                <div className="m small ellipsis" id="mentor-cert-name">
                  {domainStatus?.mentor?.cname_records?.certificate_validation
                    ?.name || ''}
                </div>
              </div>
              <div
                className="tc tcell _10 ellipsis clickable"
                onClick={() =>
                  handleCopyWrapper(
                    domainStatus?.mentor?.cname_records?.certificate_validation
                      ?.value
                  )
                }
              >
                <div className="m ellipsis" id="mentor-cert-value">
                  {domainStatus?.mentor?.cname_records?.certificate_validation
                    ?.value || ''}
                </div>
              </div>
              <div
                className="tc _10 ellipsis clickable"
                onClick={() =>
                  handleCopyWrapper(
                    domainStatus?.mentor?.cname_records?.cloudfront?.name
                  )
                }
              >
                <div className="m ellipsis" id="mentor-cloud-name">
                  {domainStatus?.mentor?.cname_records?.cloudfront?.name || ''}
                </div>
              </div>
              <div
                className="tc tcell _10 ellipsis clickable"
                onClick={() =>
                  handleCopyWrapper(
                    domainStatus?.mentor?.cname_records?.cloudfront?.value
                  )
                }
              >
                <div className="m ellipsis" id="mentor-cloud-value">
                  {domainStatus?.mentor?.cname_records?.cloudfront?.value || ''}
                </div>
              </div>
              <div className="tc tcell _10">
                {!isEditMentor ? (
                  <div
                    className="div-block-327 first active edit edit_logo"
                    onClick={() => setIsEditMentor(true)}
                  >
                    <div className="text-block-173">Edit</div>
                  </div>
                ) : isUpdatingTenantDomain ? (
                  <IBLSpinLoader color={'#FFF'} size={20} />
                ) : (
                  <div
                    className="div-block-327 first active edit edit_logo"
                    onClick={handleMentorDomainSave}
                  >
                    <div className="text-block-173">Save</div>
                  </div>
                )}
              </div>
            </div>
            <div className="tr">
              <div className="tc tcell _10">
                <div className="m">Skills</div>
              </div>
              <div className="tc tcell _10">
                <div className="m">
                  {isEditSkills ? (
                    <div
                      className="w-layout-hflex flex-block-14"
                      style={{ border: '1px solid' }}
                    >
                      <input
                        name="skills"
                        value={platformDomains?.spa_domains?.skills?.domain}
                        className="text-field-10 w-input"
                        maxLength={100}
                        data-name="Field 6"
                        type="text"
                        id="field-6"
                        onChange={handleDomainChange}
                        placeholder="e.g. skills.iblai.app"
                      />
                    </div>
                  ) : (
                    platformDomains?.spa_domains?.skills?.domain || ''
                  )}
                </div>
              </div>
              <div className="tc _10">
                <div className="m small">
                  {platformDomains?.spa_domains?.skills?.active
                    ? 'Active'
                    : 'Not Active'}
                </div>
              </div>
              <div
                className="tc _10 ellipsis clickable"
                onClick={() =>
                  handleCopyWrapper(
                    domainStatus?.skills?.cname_records?.certificate_validation
                      ?.name
                  )
                }
              >
                <div className="m small ellipsis" id="skills-cert-name">
                  {domainStatus?.skills?.cname_records?.certificate_validation
                    ?.name || ''}
                </div>
              </div>
              <div
                className="tc tcell _10 ellipsis clickable"
                onClick={() =>
                  handleCopyWrapper(
                    domainStatus?.skills?.cname_records?.certificate_validation
                      ?.value
                  )
                }
              >
                <div className="m ellipsis" id="skills-cert-value">
                  {domainStatus?.skills?.cname_records?.certificate_validation
                    ?.value || ''}
                </div>
              </div>
              <div
                className="tc _10 ellipsis clickable"
                onClick={() =>
                  handleCopyWrapper(
                    domainStatus?.skills?.cname_records?.cloudfront?.name
                  )
                }
              >
                <div className="m ellipsis" id="skills-cloud-name">
                  {domainStatus?.skills?.cname_records?.cloudfront?.name || ''}
                </div>
              </div>
              <div
                className="tc tcell _10 ellipsis clickable"
                onClick={() =>
                  handleCopyWrapper(
                    domainStatus?.skills?.cname_records?.cloudfront?.value
                  )
                }
              >
                <div className="m ellipsis" id="skills-cloud-value">
                  {domainStatus?.skills?.cname_records?.cloudfront?.value || ''}
                </div>
              </div>
              <div className="tc tcell _10">
                {!isEditSkills ? (
                  <div
                    className="div-block-327 first active edit edit_logo"
                    onClick={() => setIsEditSkills(true)}
                  >
                    <div className="text-block-173">Edit</div>
                  </div>
                ) : isUpdatingTenantDomain ? (
                  <IBLSpinLoader color={'#FFF'} size={20} />
                ) : (
                  <div
                    className="div-block-327 first active edit edit_logo"
                    onClick={handleSkillsDomainSave}
                  >
                    <div className="text-block-173">Save</div>
                  </div>
                )}
              </div>
            </div>
            <div className="tr">
              <div className="tc tcell _10">
                <div className="m">Analytics</div>
              </div>
              <div className="tc tcell _10">
                <div className="m">
                  {isEditAnalytics ? (
                    <div
                      className="w-layout-hflex flex-block-14"
                      style={{ border: '1px solid' }}
                    >
                      <input
                        name="analytics"
                        value={platformDomains?.spa_domains?.analytics?.domain}
                        className="text-field-10 w-input"
                        maxLength={100}
                        data-name="Field 6"
                        type="text"
                        id="field-6"
                        onChange={handleDomainChange}
                        placeholder="e.g. analytics.iblai.app"
                      />
                    </div>
                  ) : (
                    platformDomains?.spa_domains?.analytics?.domain || ''
                  )}
                </div>
              </div>
              <div className="tc _10">
                <div className="m small">
                  {platformDomains?.spa_domains?.analytics?.active
                    ? 'Active'
                    : 'Not Active'}
                </div>
              </div>
              <div
                className="tc _10 ellipsis clickable"
                onClick={() =>
                  handleCopyWrapper(
                    domainStatus?.analytics?.cname_records
                      ?.certificate_validation?.name
                  )
                }
              >
                <div className="m small ellipsis" id="analytics-cert-name">
                  {domainStatus?.analytics?.cname_records
                    ?.certificate_validation?.name || ''}
                </div>
              </div>
              <div
                className="tc tcell _10 ellipsis clickable"
                onClick={() =>
                  handleCopyWrapper(
                    domainStatus?.analytics?.cname_records
                      ?.certificate_validation?.value
                  )
                }
              >
                <div className="m ellipsis" id="analytics-cert-value">
                  {domainStatus?.analytics?.cname_records
                    ?.certificate_validation?.value || ''}
                </div>
              </div>
              <div
                className="tc _10 ellipsis clickable"
                onClick={() =>
                  handleCopyWrapper(
                    domainStatus?.analytics?.cname_records?.cloudfront?.name
                  )
                }
              >
                <div className="m ellipsis" id="analytics-cloud-name">
                  {domainStatus?.analytics?.cname_records?.cloudfront?.name ||
                    ''}
                </div>
              </div>
              <div
                className="tc tcell _10 ellipsis clickable"
                onClick={() =>
                  handleCopyWrapper(
                    domainStatus?.analytics?.cname_records?.cloudfront?.value
                  )
                }
              >
                <div className="m ellipsis" id="analytics-cloud-value">
                  {domainStatus?.analytics?.cname_records?.cloudfront?.value ||
                    ''}
                </div>
              </div>
              <div className="tc tcell _10">
                {!isEditAnalytics ? (
                  <div
                    className="div-block-327 first active edit edit_logo"
                    onClick={() => setIsEditAnalytics(true)}
                  >
                    <div className="text-block-173">Edit</div>
                  </div>
                ) : isUpdatingTenantDomain ? (
                  <IBLSpinLoader color={'#FFF'} size={20} />
                ) : (
                  <div
                    className="div-block-327 first active edit edit_logo"
                    onClick={handleAnalyticsDomainSave}
                  >
                    <div className="text-block-173">Save</div>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default StudioAuthoringSettingsViewContent;
