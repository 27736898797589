import {useEffect, useState} from 'react';
import {useDebounce} from "./useDebounce";
import {getTeamsList} from "./utils/teams";
import {
    getCredentialsLearnersTeamsAssignmentList,
    getLearnersTeamsAssignmentList,
    getSkillsLearnersTeamsAssignmentList
} from "./utils/teamsAssignments";  // Replace with the correct path

const useGroupsTeamsAssignmentsList = ({contentType="course",search="", page=1, size=10, refresher=null}) => {
    const [groups, setGroups] = useState([]);
    const [groupsLoading, setGroupsLoading] = useState(false);
    const [groupsPagination, setGroupsPagination] = useState({
        totalItems:0,
        currentPage:1,
        totalPages:0,
        pageSize:0
    });


    const handleTeamListRetrieval = (data) => {
        setGroups(Array.isArray(data?.results) ? data.results : [])
        setGroupsPagination({
            totalItems:  data?.count,
            currentPage: page,
            totalPages: Math.ceil(data?.count / size),
            pageSize: size,
        });
        setGroupsLoading(false)
    }

    const handleCredentialListRetrieval = (resp) => {
        const result = resp?.result
        if(!(result && Array.isArray(result?.data))){
            setGroups([])
            setGroupsLoading(false)
            return
        }
        setGroups(result.data.map((_credAssignment)=>({
            credential_name:_credAssignment?.credential_details?.name,
            group_name:_credAssignment?.group_details?.name,
            created:_credAssignment?.assigned_at,
            id:_credAssignment?.id,
            entity_id:_credAssignment?.entity_id,
        })))
        setGroupsPagination({
            totalItems:  result?.count,
            currentPage: page,
            totalPages: Math.ceil(result?.count / size),
            pageSize: size,
        });
        setGroupsLoading(false)
    }

    useDebounce(()=>{
        setGroupsLoading(true)
        switch (contentType){
            case "skill":
                getSkillsLearnersTeamsAssignmentList({
                    page_size:size,
                    page,
                    ...(!!search ? {
                        query:search
                    } : {})
                }, handleTeamListRetrieval, handleTeamListRetrieval, true)
                break;

            case "credential":
                getCredentialsLearnersTeamsAssignmentList({
                    page_size:size,
                    page,
                    ...(!!search ? {
                        search
                    } : {})
                },"groups", handleCredentialListRetrieval, handleCredentialListRetrieval)
                break;

            default:
                getLearnersTeamsAssignmentList( contentType, {
                    page_size:size,
                    page,
                    ...(!!search ? {
                        query:search
                    } : {})
                }, handleTeamListRetrieval, handleTeamListRetrieval, true)

        }
    }, 200, [search, page, size, refresher, contentType])

    return {
        groupsLoading, groups,
        groupsPagination
    }


};

export default useGroupsTeamsAssignmentsList;
