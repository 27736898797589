import React, {useContext, useEffect, useState} from "react";
import "./TeamsSkillsAssignmentsListViewContent.css"
import {getTableIndex, getTableSizes} from "../../../hooks/helpers";
import useLicensingGroup from "../../../hooks/useLicensingGroup";
import TableSizeDropdown from "../../../components/TableSizeDropdown/TableSizeDropdown";
import AddLicensingUserGroup from "../../../components/AddLicensingUserGroup/AddLicensingUserGroup";
import {AppDataContext, IBLSpinLoader} from "@iblai/ibl-web-react-common";
import EmptyTableDataInfographic from "../../../components/EmptyTableDataInfographic/EmptyTableDataInfographic";
import LicensingUsersInGroup from "../../../components/LicensingUsersInGroup/LicensingUsersInGroup";
import ReactPaginate from "react-paginate";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import useTeamsList from "../../../hooks/useTeamsList";
import SlTooltip from '@shoelace-style/shoelace/dist/react/tooltip';
import {deleteTeam} from "../../../hooks/utils/teams";
import useLearnersTeamsAssignmentsList from "../../../hooks/useLearnersTeamsAssignmentsList";
import dayjs from "dayjs";
import {deleteSkillsAssignmentAPI, deleteTeamsAssignments} from "../../../hooks/utils/teamsAssignments";

const TeamsSkillsAssignmentsListViewContent = () => {
    const { t } = useTranslation();
    const TABLE_SIZES = getTableSizes();
    const [learnerSearch, setLearnerSearch] = useState('');
    const [learnerSize, setLearnerSize] = useState(TABLE_SIZES[0]);
    const [learnerPage, setLearnerPage] = useState(1);
    const [learnerRefresher, setLearnerRefresher] = useState(null);


    const [focusedLearnerForDeletion, setFocusedLearnerForDeletion] = useState('')
    const {setNotification} = useContext(AppDataContext)
    const {learners, learnersLoading, learnersPagination}=  useLearnersTeamsAssignmentsList({contentType:"skill", search:learnerSearch, page:learnerPage, size:learnerSize, refresher:learnerRefresher})

    const paginateLearners = ({ selected }) => {
        setLearnerPage(selected + 1);
    };

    const handleAssignmentDeletion = async (info) => {
        setFocusedLearnerForDeletion(info?.id)
        if(await deleteSkillsAssignmentAPI(info?.id)){
            setNotification({
                msg:`${info?.skill} skill assignment deleted successfully.`,
                success:true
            })
            setLearnerRefresher(Date.now())
        }else{
            setNotification({
                msg:`An error occurred during the assignment deletion.`,
                success:false
            })
        }
        setFocusedLearnerForDeletion(null)
    }

    const getContentName = (singleAssignmentData) =>{
        return singleAssignmentData?.skill
        /*switch (contentType){
            case 'program' :
                return singleAssignmentData?.program_name
            case 'pathway':
                return singleAssignmentData?.pathway_name
            default:
                return singleAssignmentData?.course_name
        }*/
    }

  return (
      <>
          <div className="p-row r-r">
              <div className="div-block-204 f-w">
                  <div className="div-block-183">
                      <div className="div-block-205">
                          <div className="div-block-206"/>
                          <div className="text-block-113">Assignments | <span className={"capitalize"}>Skill</span>s</div>
                      </div>
                      <div className="div-block-308">
                          <Link to={`/teams/assignments/add-new/skills`} className="link-block-22 w-inline-block">
                              <div data-w-id="2e311dd0-e1c2-0ab6-f2a1-1e492e5123a6"
                                   className="div-block-207 first active follow-user alt-btn issue-popup">
                                  <div className="text-block-111">Assign skill</div>
                              </div>
                          </Link>
                      </div>
                  </div>
                  <div react-component="CourseTable" className="table_cont-2">
                      <div className="div-block-217">
                          <div className="div-block-169">
                              <div>
                                  <div prop-data-value-tablename="Courses" prop-data-names="tableName"
                                       className="text-block-135">Learners Assignments<br/></div>
                              </div>
                          </div>
                          <div className="div-block-169">
                              <div className="div-block-171">
                                  <div className="sm">Show</div>
                                  <div className="form-block w-form">
                                      <TableSizeDropdown setSize={setLearnerSize} setPage={setLearnerPage}/>
                                      <div className="w-form-done">
                                          <div>Thank you! Your submission has been received!</div>
                                      </div>
                                      <div className="w-form-fail">
                                          <div>Oops! Something went wrong while submitting the form.</div>
                                      </div>
                                  </div>
                                  <div className="sm">entries</div>
                              </div>
                              <div className="div-block-171">
                                  <div className="sm">Search:</div>
                                  <div className="form-block w-form">
                                      <form id="email-form-2" name="email-form-2" data-name="Email Form 2" method="get"
                                            data-wf-page-id="66e1c2afd7d305ab933f7a97"
                                            data-wf-element-id="4386085a-1eef-af03-c6b1-7a5a39f298f9">
                                          <input
                                              className="input w-input"
                                              data-name="Field 3"
                                              id="field-3"
                                              maxLength={256}
                                              name="field-3"
                                              placeholder=""
                                              required=""
                                              type="text"
                                              value={learnerSearch}
                                              onChange={(e) => {
                                                  setLearnerPage(1)
                                                  setLearnerSearch(e?.target?.value);
                                              }}/>
                                      </form>
                                      <div className="w-form-done">
                                          <div>Thank you! Your submission has been received!</div>
                                      </div>
                                      <div className="w-form-fail">
                                          <div>Oops! Something went wrong while submitting the form.</div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div react-component="table" className="table">
                              <div className="th">
                                  <div className="tc _10">
                                      <div className="sm">#</div>
                                  </div>
                                  <div className="tc _30">
                                      <div className="w_sort">
                                          <div className="text-block-134 fa_icon sort"></div>
                                          <div className="sm">Learner</div>
                                      </div>
                                  </div>
                                  <div className="tc _30">
                                      <div className="w_sort">
                                          <div className="text-block-134 fa_icon sort"></div>
                                          <div className="sm">Assigned Skill</div>
                                      </div>
                                  </div>
                                  <div className="tc _20">
                                      <div className="w_sort">
                                          <div className="text-block-134 fa_icon sort"></div>
                                          <div className="sm">Point</div>
                                      </div>
                                  </div>
                              </div>
                              {
                                  learnersLoading ?
                                      <IBLSpinLoader containerHeight={"200px"} size={20} color={"white"}/>
                                      :
                                      learners.length > 0 ?
                                          learners.map((learner, index) => (
                                              <div className="tr">
                                                  <div className="tc tcell _10">
                                                      <div
                                                          className="m">{getTableIndex(index, learnerPage, learnerSize)}</div>
                                                  </div>
                                                  <div data-w-id="4386085a-1eef-af03-c6b1-7a5a39f2991c"
                                                       className="tc tcell _30"><img
                                                      src="/images/female20091023302387177.jpeg" loading="lazy" alt
                                                      className="r-i hidden"/>
                                                      <div
                                                          className="tl-2 np">{learner?.name ?? learner?.username}</div>
                                                  </div>
                                                  <div data-w-id="4386085a-1eef-af03-c6b1-7a5a39f2991c"
                                                       className="tc tcell _30"><img
                                                      src="/images/female20091023302387177.jpeg" loading="lazy" alt
                                                      className="r-i hidden"/>
                                                      <div
                                                          className="tl-2 np">{learner?.skill}</div>
                                                  </div>
                                                  <div className="tc tcell _20">
                                                      <div
                                                          className="m small">{learner?.skill_points}</div>
                                                  </div>
                                                  {/*<div className="tc tcell _10">
                                                      <SlTooltip>
                                                          <div style={{textAlign: "center"}} slot="content">
                                                              <span>View all {contentType}s</span>
                                                          </div>
                                                          <Link
                                                              to={`/teams/assignments/content/${learner?.id}`}
                                                              className="w-inline-block"><img src="/images/eye.svg"
                                                                                              loading="lazy" alt
                                                                                              className="image-154"/></Link>
                                                      </SlTooltip>
                                                  </div>*/}
                                                  <div onClick={() => handleAssignmentDeletion(learner)}
                                                       className="tc tcell _10">
                                                      <SlTooltip>
                                                          <div style={{textAlign: "center"}} slot="content">
                                                              <span>Delete assignment</span>
                                                          </div>
                                                          <div>
                                                              {focusedLearnerForDeletion === learner?.id ?
                                                                  (
                                                                      <IBLSpinLoader size={20} color={"#FFF"}/>
                                                                  )
                                                                  : (
                                                                      <img
                                                                          src="/images/trash-2.svg" loading="lazy" alt
                                                                          className="image-154"/>
                                                                  )
                                                              }
                                                          </div>
                                                      </SlTooltip>
                                                  </div>
                                              </div>
                                          ))
                                          :
                                          <EmptyTableDataInfographic/>

                              }
                              <div className="tf pagination-container">
                                  <div className="div-block-289">
                                      <div className="info-3">
                                          {t('Showing')}{' '}
                                          {(learnerPage - 1) * learnerSize + 1}{' '}
                                          {t('to')}{' '}
                                          {Math.min(learnerPage * learnerSize, learnersPagination.totalItems)}{' '}
                                          {t('of')} {learnersPagination.totalItems}
                                      </div>
                                      <ReactPaginate
                                          onPageChange={paginateLearners}
                                          pageCount={learnersPagination.totalPages}
                                          forcePage={learnerPage - 1}
                                          previousLabel={'Prev'}
                                          nextLabel={'Next'}
                                          containerClassName={'pagination'}
                                          pageLinkClassName={'page-number'}
                                          previousLinkClassName={'page-number'}
                                          nextLinkClassName={'page-number'}
                                          activeLinkClassName={'active'}
                                      />
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  {/*<div react-component="CourseTable" className="table_cont-2">
                      <div className="div-block-217">
                          <div className="div-block-169">
                              <div>
                                  <div prop-data-value-tablename="Courses" prop-data-names="tableName"
                                       className="text-block-135">Teams Assignments<br/></div>
                              </div>
                          </div>
                          <div className="div-block-169">
                              <div className="div-block-171">
                                  <div className="sm">Show</div>
                                  <div className="form-block w-form">
                                      <form id="email-form-2" name="email-form-2" data-name="Email Form 2" method="get"
                                            data-wf-page-id="67a0aa5a53c83714209a8028"
                                            data-wf-element-id="e6beb8e0-510b-9a9c-81a2-940f7f1a8e9f"><select
                                          id="field-2" name="field-2" data-name="Field 2" prop-events-names="onChange"
                                          prop-events-value-onchange="handleEntriesDropdownOnChange"
                                          className="show w-select">
                                          <option value>10</option>
                                          <option value="First">25</option>
                                          <option value="Second">50</option>
                                          <option value="Third">100</option>
                                      </select></form>
                                      <div className="w-form-done">
                                          <div>Thank you! Your submission has been received!</div>
                                      </div>
                                      <div className="w-form-fail">
                                          <div>Oops! Something went wrong while submitting the form.</div>
                                      </div>
                                  </div>
                                  <div className="sm">entries</div>
                              </div>
                              <div className="div-block-171">
                                  <div className="sm">Search:</div>
                                  <div className="form-block w-form">
                                      <form id="email-form-2" name="email-form-2" data-name="Email Form 2" method="get"
                                            data-wf-page-id="67a0aa5a53c83714209a8028"
                                            data-wf-element-id="e6beb8e0-510b-9a9c-81a2-940f7f1a8ead"><input
                                          className="input w-input" maxLength={256} name="field-3" data-name="Field 3"
                                          placeholder prop-events-value-onchange="handleSearchBarOnChange" type="text"
                                          id="field-3" prop-events-names="onChange" required/></form>
                                      <div className="w-form-done">
                                          <div>Thank you! Your submission has been received!</div>
                                      </div>
                                      <div className="w-form-fail">
                                          <div>Oops! Something went wrong while submitting the form.</div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div react-component="table" className="table">
                              <div className="th">
                                  <div className="tc _10">
                                      <div className="sm">#</div>
                                  </div>
                                  <div className="tc _30">
                                      <div className="w_sort">
                                          <div className="text-block-134 fa_icon sort"></div>
                                          <div className="sm">Team</div>
                                      </div>
                                  </div>
                                  <div className="tc _30">
                                      <div className="w_sort">
                                          <div className="text-block-134 fa_icon sort"></div>
                                          <div className="sm">Created at</div>
                                      </div>
                                  </div>
                                  <div className="tc _30">
                                      <div className="w_sort">
                                          <div className="text-block-134 fa_icon sort"></div>
                                          <div className="sm">Status</div>
                                      </div>
                                  </div>
                              </div>
                              <div className="tr">
                                  <div className="tc tcell _10">
                                      <div className="m">1</div>
                                  </div>
                                  <div className="tc tcell _30"><img src="/images/female20091023302387177.jpeg"
                                                                     loading="lazy" alt className="r-i hidden"/>
                                      <a href="per-learner/activity.html" className="tl-2 np">Team Alpha</a>
                                  </div>
                                  <div className="tc tcell _30">
                                      <div className="m small">22 Jan 2025</div>
                                  </div>
                                  <div className="tc tcell _10"><img src="/images/eye.svg" loading="lazy" alt
                                                                     className="image-154"/></div>
                                  <div className="tc tcell _10"><img src="/images/settings-1.svg" loading="lazy" alt
                                                                     className="image-154"/></div>
                                  <div className="tc tcell _10"><img src="/images/trash-2.svg" loading="lazy" alt
                                                                     className="image-154"/></div>
                              </div>
                              <div className="tr">
                                  <div className="tc tcell _10">
                                      <div className="m">1</div>
                                  </div>
                                  <div className="tc tcell _30"><img src="/images/female20091023302387177.jpeg"
                                                                     loading="lazy" alt className="r-i hidden"/>
                                      <a href="per-learner/activity.html" className="tl-2 np">Team Alpha</a>
                                  </div>
                                  <div className="tc tcell _30">
                                      <div className="m small">22 Jan 2025</div>
                                  </div>
                                  <div className="tc tcell _10"><img src="/images/eye.svg" loading="lazy" alt
                                                                     className="image-154"/></div>
                                  <div className="tc tcell _10"><img src="/images/settings-1.svg" loading="lazy" alt
                                                                     className="image-154"/></div>
                                  <div className="tc tcell _10"><img src="/images/trash-2.svg" loading="lazy" alt
                                                                     className="image-154"/></div>
                              </div>
                              <div className="tr">
                                  <div className="tc tcell _10">
                                      <div className="m">1</div>
                                  </div>
                                  <div className="tc tcell _30"><img src="/images/female20091023302387177.jpeg"
                                                                     loading="lazy" alt className="r-i hidden"/>
                                      <a href="per-learner/activity.html" className="tl-2 np">Team Alpha</a>
                                  </div>
                                  <div className="tc tcell _30">
                                      <div className="m small">22 Jan 2025</div>
                                  </div>
                                  <div className="tc tcell _10"><img src="/images/eye.svg" loading="lazy" alt
                                                                     className="image-154"/></div>
                                  <div className="tc tcell _10"><img src="/images/settings-1.svg" loading="lazy" alt
                                                                     className="image-154"/></div>
                                  <div className="tc tcell _10"><img src="/images/trash-2.svg" loading="lazy" alt
                                                                     className="image-154"/></div>
                              </div>
                              <div className="tr">
                                  <div className="tc tcell _10">
                                      <div className="m">1</div>
                                  </div>
                                  <div className="tc tcell _30"><img src="/images/female20091023302387177.jpeg"
                                                                     loading="lazy" alt className="r-i hidden"/>
                                      <a href="per-learner/activity.html" className="tl-2 np">Team Alpha</a>
                                  </div>
                                  <div className="tc tcell _30">
                                      <div className="m small">22 Jan 2025</div>
                                  </div>
                                  <div className="tc tcell _10"><img src="/images/eye.svg" loading="lazy" alt
                                                                     className="image-154"/></div>
                                  <div className="tc tcell _10"><img src="/images/settings-1.svg" loading="lazy" alt
                                                                     className="image-154"/></div>
                                  <div className="tc tcell _10"><img src="/images/trash-2.svg" loading="lazy" alt
                                                                     className="image-154"/></div>
                              </div>
                              <div className="tr">
                                  <div className="tc tcell _10">
                                      <div className="m">1</div>
                                  </div>
                                  <div className="tc tcell _30"><img src="/images/female20091023302387177.jpeg"
                                                                     loading="lazy" alt className="r-i hidden"/>
                                      <a href="per-learner/activity.html" className="tl-2 np">Team Alpha</a>
                                  </div>
                                  <div className="tc tcell _30">
                                      <div className="m small">22 Jan 2025</div>
                                  </div>
                                  <div className="tc tcell _10"><img src="/images/eye.svg" loading="lazy" alt
                                                                     className="image-154"/></div>
                                  <div className="tc tcell _10"><img src="/images/settings-1.svg" loading="lazy" alt
                                                                     className="image-154"/></div>
                                  <div className="tc tcell _10"><img src="/images/trash-2.svg" loading="lazy" alt
                                                                     className="image-154"/></div>
                              </div>
                              <div className="tr">
                                  <div className="tc tcell _10">
                                      <div className="m">1</div>
                                  </div>
                                  <div className="tc tcell _30"><img src="/images/female20091023302387177.jpeg"
                                                                     loading="lazy" alt className="r-i hidden"/>
                                      <a href="per-learner/activity.html" className="tl-2 np">Team Alpha</a>
                                  </div>
                                  <div className="tc tcell _30">
                                      <div className="m small">22 Jan 2025</div>
                                  </div>
                                  <div className="tc tcell _10"><img src="/images/eye.svg" loading="lazy" alt
                                                                     className="image-154"/></div>
                                  <div className="tc tcell _10"><img src="/images/settings-1.svg" loading="lazy" alt
                                                                     className="image-154"/></div>
                                  <div className="tc tcell _10"><img src="/images/trash-2.svg" loading="lazy" alt
                                                                     className="image-154"/></div>
                              </div>
                              <div className="tr">
                                  <div className="tc tcell _10">
                                      <div className="m">1</div>
                                  </div>
                                  <div className="tc tcell _30"><img src="/images/female20091023302387177.jpeg"
                                                                     loading="lazy" alt className="r-i hidden"/>
                                      <a href="per-learner/activity.html" className="tl-2 np">Team Alpha</a>
                                  </div>
                                  <div className="tc tcell _30">
                                      <div className="m small">22 Jan 2025</div>
                                  </div>
                                  <div className="tc tcell _10"><img src="/images/eye.svg" loading="lazy" alt
                                                                     className="image-154"/></div>
                                  <div className="tc tcell _10"><img src="/images/settings-1.svg" loading="lazy" alt
                                                                     className="image-154"/></div>
                                  <div className="tc tcell _10"><img src="/images/trash-2.svg" loading="lazy" alt
                                                                     className="image-154"/></div>
                              </div>
                              <div className="tr">
                                  <div className="tc tcell _10">
                                      <div className="m">1</div>
                                  </div>
                                  <div className="tc tcell _30"><img src="/images/female20091023302387177.jpeg"
                                                                     loading="lazy" alt className="r-i hidden"/>
                                      <a href="per-learner/activity.html" className="tl-2 np">Team Alpha</a>
                                  </div>
                                  <div className="tc tcell _30">
                                      <div className="m small">22 Jan 2025</div>
                                  </div>
                                  <div className="tc tcell _10"><img src="/images/eye.svg" loading="lazy" alt
                                                                     className="image-154"/></div>
                                  <div className="tc tcell _10"><img src="/images/settings-1.svg" loading="lazy" alt
                                                                     className="image-154"/></div>
                                  <div className="tc tcell _10"><img src="/images/trash-2.svg" loading="lazy" alt
                                                                     className="image-154"/></div>
                              </div>
                              <div className="tr">
                                  <div className="tc tcell _10">
                                      <div className="m">1</div>
                                  </div>
                                  <div className="tc tcell _30"><img src="/images/female20091023302387177.jpeg"
                                                                     loading="lazy" alt className="r-i hidden"/>
                                      <a href="per-learner/activity.html" className="tl-2 np">Team Alpha</a>
                                  </div>
                                  <div className="tc tcell _30">
                                      <div className="m small">22 Jan 2025</div>
                                  </div>
                                  <div className="tc tcell _10"><img src="/images/eye.svg" loading="lazy" alt
                                                                     className="image-154"/></div>
                                  <div className="tc tcell _10"><img src="/images/settings-1.svg" loading="lazy" alt
                                                                     className="image-154"/></div>
                                  <div className="tc tcell _10"><img src="/images/trash-2.svg" loading="lazy" alt
                                                                     className="image-154"/></div>
                              </div>
                              <div className="tr">
                                  <div className="tc tcell _10">
                                      <div className="m">1</div>
                                  </div>
                                  <div className="tc tcell _30"><img src="/images/female20091023302387177.jpeg"
                                                                     loading="lazy" alt className="r-i hidden"/>
                                      <a href="per-learner/activity.html" className="tl-2 np">Team Alpha</a>
                                  </div>
                                  <div className="tc tcell _30">
                                      <div className="m small">22 Jan 2025</div>
                                  </div>
                                  <div className="tc tcell _10"><img src="/images/eye.svg" loading="lazy" alt
                                                                     className="image-154"/></div>
                                  <div className="tc tcell _10"><img src="/images/settings-1.svg" loading="lazy" alt
                                                                     className="image-154"/></div>
                                  <div className="tc tcell _10"><img src="/images/trash-2.svg" loading="lazy" alt
                                                                     className="image-154"/></div>
                              </div>
                              <div className="tf">
                                  <div className="div-block-289">
                                      <div className="info-3">Showing 1 to 9 of 9 entries</div>
                                      <div className="pagination">
                                          <div className="arrow-invite-previous-disabled p-item">
                                              <div className="text-block fa_icon fa-arrow disabled"></div>
                                          </div>
                                          <div className="p-active p-item">
                                              <div>1</div>
                                          </div>
                                          <div className="p-item arrow-next disabled">
                                              <div className="text-block fa_icon fa-arrow"></div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>*/}
              </div>
          </div>
      </>
  );
};

export default TeamsSkillsAssignmentsListViewContent;
