import {useEffect, useState} from 'react';
import {useDebounce} from "./useDebounce";
import {getTeamsList} from "./utils/teams";
import {
    getCredentialsLearnersTeamsAssignmentList,
    getLearnersTeamsAssignmentList,
    getSkillsLearnersTeamsAssignmentList
} from "./utils/teamsAssignments";  // Replace with the correct path

const useLearnersTeamsAssignmentsList = ({contentType="course",search="", page=1, size=10, refresher=null}) => {
    const [learners, setLearners] = useState([]);
    const [learnersLoading, setLearnersLoading] = useState(false);
    const [learnersPagination, setLearnersPagination] = useState({
        totalItems:0,
        currentPage:1,
        totalPages:0,
        pageSize:0
    });


    const handleTeamListRetrieval = (data) => {
        setLearners(Array.isArray(data?.results) ? data.results : [])
        setLearnersPagination({
            totalItems:  data?.count,
            currentPage: page,
            totalPages: Math.ceil(data?.count / size),
            pageSize: size,
        });
        setLearnersLoading(false)
    }
    const handleCredentialListRetrieval = (resp) => {
        const result = resp?.result
        if(!(result && Array.isArray(result?.data))){
            setLearners([])
            setLearnersLoading(false)
            return
        }
        setLearners(result.data.map((_credAssignment)=>({
            credential_name:_credAssignment?.credential_details?.name,
            name:_credAssignment?.user_details?.name || _credAssignment?.user_details?.username,
            created:_credAssignment?.assigned_at,
            id:_credAssignment?.id,
            entity_id:_credAssignment?.entity_id,
        })))
        setLearnersPagination({
            totalItems:  result?.count,
            currentPage: page,
            totalPages: Math.ceil(result?.count / size),
            pageSize: size,
        });
        setLearnersLoading(false)
    }


    useDebounce(()=>{
        setLearnersLoading(true)
        switch (contentType){
            case "skill":
                getSkillsLearnersTeamsAssignmentList({
                    page_size:size,
                    page,
                    ...(!!search ? {
                        query:search
                    } : {})
                }, handleTeamListRetrieval, handleTeamListRetrieval)
                break;
            case "credential":
                getCredentialsLearnersTeamsAssignmentList({
                    page_size:size,
                    page,
                    ...(!!search ? {
                        search
                    } : {})
                },"users", handleCredentialListRetrieval, handleCredentialListRetrieval)
                break;
            default:
                getLearnersTeamsAssignmentList( contentType, {
                    page_size:size,
                    page,
                    ...(!!search ? {
                        query:search
                    } : {})
                }, handleTeamListRetrieval, handleTeamListRetrieval)
                break;
        }
    }, 200, [search, page, size, refresher, contentType])

    return {
        learnersLoading, learners,
        learnersPagination
    }


};

export default useLearnersTeamsAssignmentsList;
